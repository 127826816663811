body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

header div {
    flex: 1 1 auto;
}

header a {
    text-decoration: none;
}

header a:hover {
    text-decoration: underline;
}

header h2 {   
    color: #000;
}

header .header-title small { 
    color: #999;
}

header .header-nav {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1em;
    gap: 1em;
}

header .header-nav a {
    margin: 5px;
    font-weight: 500;
    color: #999;
}

header .header-nav a:hover {
    margin: 5px;
    font-weight: 500;
    color: #09f;
}

header .header-nav a.active {
    border: 0px;
    color: #000;
}

svg {
    transition: all 0.2s ease;
    -webkit-transform: scale(1);
            transform: scale(1);
}

svg:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    color: #09f;
}
.App {
  width: 100%;
}

.jumbotron {
  margin-bottom: 0px !important;
}

.navbar {
  border: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

a.active {
  background-color: #fff !important;
  border: 1px solid #eee;
  border-bottom: 0px;
}

.footer {
  padding: 2em;
  font-style: italic;
  font-size: 0.75em;
}

@media screen and (max-width: 768px) {
  .footer {
    margin-bottom: 8em;
  }
}
.gallery .thumbnails {
    width: 100%;
    margin-bottom: 5px;

    overflow-y: scroll;

    display: flex;
    flex-wrap: nowrap;
}

.gallery .thumbnails img {
    width: 100px;
    margin-right: 5px;
}

.gallery .tiles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
}

.gallery .tiles .gallery-tile {
    position: relative;

    margin: 8px;
    flex: 1 1 auto;

    width: 300px;
    height: 300px;
    overflow: hidden;

    transition: all 200ms ease;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}

.gallery .tiles .gallery-tile:hover {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

.gallery .tiles .gallery-tile img {
    width: 100%;
}

.gallery .tiles .gallery-tile .image-info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 0.7em);
    padding: 0.35em;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    font-style: italic;
    opacity: 0;
    transition: opacity 200ms ease;
}

.gallery .tiles .gallery-tile:hover .image-info {
    opacity: 1;
}

.gallery .tiles .gallery-tile .image-info div:first-child {
    font-size: 1.25em;
    font-weight: bold;
    font-style: normal;
}
    
.gallery .selected-image {
    width: 100%;
}

.gallery .scroll-bar {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.gallery .scroll-bar .gallery-caret {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    height: 8px;
    border-radius: 4px;

    top: 0px;
}
.image-navigation {
    width: 100%;
    padding: 10px;
    font-size: 120%;
    font-weight: 500;

    display: flex;
}

.image-navigation div {
    flex: 1 1 50%;
}

.image-navigation div .right-nav {
    text-align: right;
}

.image-container {
    width: 100%;
}

.image-container .image {
    flex: 3 3 auto;
}

.image-container .image img {
    width: 100%;
}

.image-container .details {
    font-size: 120%;
    background-color: white;
}

.image-container .details .img-title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px;
    margin-bottom: 2px;
}

.image-container .details .img-year {
    font-size: 120%;
    font-weight: 500;
}

.extra-images-container {
    margin-top: 2em;
    padding-left: 2em;

    display: flex;
    flex-flow: wrap;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
}

.extra-images-container img {
    cursor: zoom-in;
    transition: 0.2s ease all;
}

.extra-images-container img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

@media screen and (min-width: 768px) {
    .image-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .image-container .details {
        min-width: 300px;
        margin-left: 20px;
    }

    .image-container .details {
        flex: 1 1 auto;
        min-width: 40%;
        max-width: 500px;
        margin-left: 20px;
        border-radius: 10px;
        padding: 15px;
    }
}

@media screen and (max-width: 768px) {
    .image-container .details {
        position: fixed;
        left: 0px;
        width: 100%;
        bottom: 0px;
        padding: 5px;
        
        display: flex;

        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .image-container .details h4 {
        flex: 0 0 auto;
        max-width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .image-container .details h4 {
        max-width: 33%;
    }
}
.events-page {
    padding: 2em;
}

.events-page > h4 {
    width: 80%;
    border-bottom: 1px solid #999;
}

.event-list {
    padding-left: 1em;
}

.event-list h4 {
    margin-bottom: 0.5em;
}
.contact-page {
    padding-left: 2em;
}
