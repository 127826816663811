.App {
  width: 100%;
}

.jumbotron {
  margin-bottom: 0px !important;
}

.navbar {
  border: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

a.active {
  background-color: #fff !important;
  border: 1px solid #eee;
  border-bottom: 0px;
}

.footer {
  padding: 2em;
  font-style: italic;
  font-size: 0.75em;
}

@media screen and (max-width: 768px) {
  .footer {
    margin-bottom: 8em;
  }
}