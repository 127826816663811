.image-navigation {
    width: 100%;
    padding: 10px;
    font-size: 120%;
    font-weight: 500;

    display: flex;
}

.image-navigation div {
    flex: 1 1 50%;
}

.image-navigation div .right-nav {
    text-align: right;
}

.image-container {
    width: 100%;
}

.image-container .image {
    flex: 3 3 auto;
}

.image-container .image img {
    width: 100%;
}

.image-container .details {
    font-size: 120%;
    background-color: white;
}

.image-container .details .img-title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px;
    margin-bottom: 2px;
}

.image-container .details .img-year {
    font-size: 120%;
    font-weight: 500;
}

.extra-images-container {
    margin-top: 2em;
    padding-left: 2em;

    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 2em;
}

.extra-images-container img {
    cursor: zoom-in;
    transition: 0.2s ease all;
}

.extra-images-container img:hover {
    transform: scale(1.05);
}

@media screen and (min-width: 768px) {
    .image-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .image-container .details {
        min-width: 300px;
        margin-left: 20px;
    }

    .image-container .details {
        flex: 1 1 auto;
        min-width: 40%;
        max-width: 500px;
        margin-left: 20px;
        border-radius: 10px;
        padding: 15px;
    }
}

@media screen and (max-width: 768px) {
    .image-container .details {
        position: fixed;
        left: 0px;
        width: 100%;
        bottom: 0px;
        padding: 5px;
        
        display: flex;

        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .image-container .details h4 {
        flex: 0 0 auto;
        max-width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .image-container .details h4 {
        max-width: 33%;
    }
}